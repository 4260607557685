import * as React from 'react'
import { Link } from 'gatsby'

import * as styles from './index.module.scss'

// const TagListItem: React.FC<Props> = ({
//   isSelected,
//   tag,
//   totalCount,
//   maxTotalCount,
//   onSelectedTag,
// }) => {
function TagListItem({
  isSelected,
  tag,
  totalCount,
  maxTotalCount,
  onSelectedTag,
}: Props) {
  const getFontSize = () => {
    let fontSize = Math.round(50 / (maxTotalCount / totalCount)).toString()
    if (fontSize.length <= 1) fontSize = `0${fontSize}`
    return `${Number(fontSize) / 100 + 0.9}rem`
  }

  return (
    <li key={tag} role='tab'>
      <span
        className={`${styles.tag} ${isSelected ? styles.selected : ''}`}
        style={{ fontSize: getFontSize() }}
        onClick={() => onSelectedTag(tag)}
        onKeyDown={() => void 0}
        role='button'
        tabIndex={0}
      >
        <Link to={`#${tag}`}>{tag}</Link>
      </span>
    </li>
  )
}

interface Props {
  isSelected: boolean
  tag: string
  totalCount: number
  maxTotalCount: number
  onSelectedTag: (tag: string | undefined) => void
}

export default TagListItem
