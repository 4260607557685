import { graphql } from 'gatsby'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import PostList from '../../components/post-list'
import SEO from '../../components/seo'
import TagList from '../../components/tag-list'
import { useTags } from '../../hooks/use-tags'
import Layout from '../../layout'
import { INode } from '../../types/mdx'
import * as styles from './index.module.scss'

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            excerpt(truncate: true)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMM DD, YYYY")
              update(formatString: "MMM DD, YYYY")
              title
              tags
            }
          }
        }
      }
    }
  }
`

export interface IGroupItem {
  fieldValue: string
  totalCount: number
  edges: INode[]
}

export interface IProps {
  data: {
    allMdx: {
      group: IGroupItem[]
    }
  }
}

function sorted(group: IGroupItem[]): IGroupItem[] {
  group.sort(function (a: IGroupItem, b: IGroupItem) {
    const x = a.fieldValue.toLocaleLowerCase()
    const y = b.fieldValue.toLocaleLowerCase()

    if (x < y) return -1
    if (y < x) return 1
    return 0
  })

  return group
}

function TagsPage({ data }: IProps) {
  const group = sorted(data.allMdx.group)
  const [currentPostList, setCurrentPostList] = useState<INode[]>([])
  const { selectedTag, setSelectedTag, maxTotalCount } = useTags(group)

  const getPostList = useCallback(() => {
    if (group.filter((g: IGroupItem) => g.fieldValue === selectedTag).length) {
      return group.filter((g: IGroupItem) => g.fieldValue === selectedTag)[0]
        .edges
    }

    return []
  }, [selectedTag])

  useEffect(() => {
    if (typeof selectedTag === `undefined`) return
    const postList = getPostList()
    postList.length > 0 && setCurrentPostList(postList)
  }, [selectedTag])

  return (
    <Layout>
      <SEO title={`TAGS`} />

      <div id={styles.tags}>
        <h1 className={styles.title}>Tags</h1>

        <TagList
          groupItems={group}
          maxTotalCount={maxTotalCount}
          selectedTag={selectedTag}
          onSelectedTag={setSelectedTag}
        />

        <PostList classNames={styles.postList} posts={currentPostList} />
      </div>
    </Layout>
  )
}

export default TagsPage
