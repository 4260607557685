import * as React from 'react'
import { useEffect } from 'react'
import TagListItem from '../tag-list-item'

import { INode } from '../../types/mdx'

import * as styles from './index.module.scss'

interface IGroupItem {
  fieldValue: string
  totalCount: number
  edges: INode[]
}

interface IProps {
  groupItems: IGroupItem[]
  maxTotalCount: number
  selectedTag: string | undefined
  onSelectedTag: (tag: string | undefined) => void
}

function TagList({
  groupItems,
  maxTotalCount,
  selectedTag,
  onSelectedTag,
}: IProps) {
  useEffect(() => {
    onSelectedTag(selectedTag)
  }, [])

  return (
    <div className={styles.tagListWrap}>
      <ul role='tablist'>
        {groupItems.map(({ fieldValue: tagName, totalCount }) => (
          <TagListItem
            key={tagName}
            isSelected={tagName === selectedTag}
            tag={tagName}
            totalCount={totalCount}
            maxTotalCount={maxTotalCount}
            onSelectedTag={onSelectedTag}
          />
        ))}
      </ul>
    </div>
  )
}

export default TagList
